import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import cyrillicToTranslit from 'cyrillic-to-translit-js';

import request from '../../lib/request';
import File from '../../entity/File';

import './styles.scss';

interface Props {
  label: string;
  name: string;
  value: number | null;
  accept?: string;
  extRegEx?: string;
  changeEvent: (name: string, value: number | null, remove?: number) => void;
}

const FieldText: FC<Props> = (props) => {
  const [file, setFile] = useState({} as File);

  const titleChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const newObj = {} as File;
    Object.assign(newObj, file);
    newObj.title = target.value ? target.value : '';
    setFile(newObj);
  };

  const titleSave = () => {
    if (file.id) {
      request(`/cms/api/files/save/${file.id}`, {
        method: 'POST',
        body: JSON.stringify({ title: file.title }),
      });
    } else {
      request('/cms/api/files/save', {
        method: 'POST',
        body: JSON.stringify({ title: file.title }),
        success: (response) => {
          const newFile = new File();
          newFile.fill(response);
          setFile(newFile);
          if (newFile.id) {
            props.changeEvent(props.name, newFile.id);
          }
        },
      });
    }
  };

  const clearFile = () => {
    setFile({} as File);
    props.changeEvent(props.name, null, props.value);
  };

  const fileSave = async (event: ChangeEvent) => {
    // @ts-ignore
    const fileName = cyrillicToTranslit().transform(event.target.files[0].name, '_');

    if (props.extRegEx) {
      // eslint-disable-next-line
      const regex = new RegExp(`.*\.${props.extRegEx}$`);
      if (!regex.test(fileName)) {
        clearFile();
        alert('Недопустимое расширение файла');
        return;
      }
    }

    const target = event.target as HTMLInputElement;
    const formData = new FormData();

    formData.append('file', target.files[0], fileName);

    const response = await fetch(`/cms/api/files/save-file${file.id ? `/${file.id}` : ''}`, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const newFile = new File();
      const data = await response.json();
      newFile.fill(data);
      setFile(newFile);
      if (newFile.id) {
        props.changeEvent(props.name, newFile.id);
      }
    }

    // @ts-ignore
    event.target.value = null;
  };

  useEffect(() => {
    const loadData = () => {
      if (!props.value) {
        return;
      }

      request(`/cms/api/files/get/${props.value}`, {
        success: (response) => {
          if (response && response.id) {
            const newFile = new File();
            newFile.fill(response);
            setFile(newFile);
          }
        },
      });
    };

    loadData();
  }, [props.value]);

  return (
    <div className='card mb-3'>
      <div className='card-body'>
        {file.id && (
          <button
            className='btn btn-sm btn-danger clear-file'
            type='button'
            onClick={() => clearFile()}
          >
            &times;
          </button>
        )}
        <label className='form-label' htmlFor={props.name}>
          {props.label}
        </label>
        <input
          className='form-control mb-1'
          id={`${props.name}-file-title`}
          placeholder='Название файла'
          type='text'
          value={file.title ? file.title : ''}
          onBlur={titleSave}
          onChange={titleChange}
        />
        <label className='btn btn-success btn-sm file-btn'>
          <input accept={props.accept} id={`${props.name}-file`} type='file' onChange={fileSave} />
          Загрузить файл
        </label>
        {file.path && (
          <a href={file.path} rel='noreferrer' target='_blank'>
            Просмотреть
          </a>
        )}
      </div>
    </div>
  );
};

export default FieldText;
