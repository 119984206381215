import ContentEntity from './ContentEntity';

export interface Criteria {
  questionId: number;
  answerId: number;
}

export default class Article extends ContentEntity {
  criterias: Array<Array<Criteria>>;

  order: number;

  category: string;

  static labelOne = 'Взаимодействие c клиентом';

  static labelMany = 'Взаимодействие c клиентом';

  fill(data: any) {
    super.fill(data);
    this.criterias = data.criterias || [];
    this.order = data.order || 0;
    this.category = data.category || '';
  }

  static tableFields = ['title'];

  static editableField = {
    ...ContentEntity.editableField,
    criterias: {
      type: 'criteriaMultiple',
      label: 'Фильтры',
      order: 55,
    },
    category: {
      type: 'text',
      label: 'Категория',
      placeholder: 'Категория',
      order: 11,
    },
    order: {
      type: 'text',
      label: 'Приоритет',
      placeholder: 'Приоритет',
      order: 56,
    },
  };
}
