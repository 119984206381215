import ContentEntity from './ContentEntity';

export default class News extends ContentEntity {
  publishDate: Date;

  static labelOne = 'Новость';

  static labelMany = 'Новости';

  fill(data: any) {
    super.fill(data);
    this.publishDate = new Date(data.publishDate) || new Date();
  }

  static editableField = {
    ...ContentEntity.editableField,
    publishDate: {
      type: 'date',
      label: 'Дата публикации',
      order: 20,
    },
  };
}
