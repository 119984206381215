import React, { ChangeEvent, FC } from 'react';

import './styles.scss';

interface Props {
  label: string;
  changeEvent: (event: ChangeEvent) => void;
  name: string;
  value: boolean;
  placeholder?: string;
}

const FieldText: FC<Props> = (props) => {
  return (
    <div className='mb-3'>
      <label className='form-label'>
        <input
          checked={!!props.value}
          id={props.name}
          name={props.name}
          type='checkbox'
          onChange={props.changeEvent}
        />
        <span>{props.label}</span>
      </label>
    </div>
  );
};

export default FieldText;
