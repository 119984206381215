import Answer from './Answer';

export default class Question {
  id: number | null;

  title: string;

  answers: Answer[];

  disabled: boolean;

  static labelOne = 'Вопрос';

  static labelMany = 'Вопросы';

  fill(data: any) {
    this.id = Number(data.id) || null;
    this.title = data.title || '';
    this.answers = data.answers || [];
    this.disabled = data.disabled;
  }

  static tableFields = ['title'];

  static editableField = {
    title: {
      type: 'text',
      label: 'Вопрос',
      placeholder: 'Вопрос',
    },
    answers: {
      type: 'answerMultiple',
      label: 'Ответы',
    },
    disabled: {
      type: 'checkbox',
      label: 'Отключен',
    },
  };
}
