import { IRoutes } from './types/types';
import Policy from './pages/Policy';
import FaqPage from './pages/Faq';
import EntityList, { setTitle } from './pages/EntityList';
import EntityEdit, { setEditTitle } from './pages/EntityEdit';
import Auth, { authBeforeLoad, logoutBeforeLoad } from './pages/Auth';
import UserGroup from './entity/UserGroup';
import User from './entity/User';
import Question from './entity/Question';
import News from './entity/News';
import Instruction from './entity/Instruction';
import Faq from './entity/Faq';
import Article from './entity/Article';
import Answer from './entity/Answer';

const ROUTES: IRoutes = {
  auth: {
    path: '/',
    page: Auth,
    method: authBeforeLoad,
  },
  logout: {
    path: '/logout',
    page: Auth,
    method: logoutBeforeLoad,
  },
  users: {
    path: '/users',
    page: EntityList,
    method: setTitle,
    entity: User,
  },
  user: {
    path: '/users/:id',
    page: EntityEdit,
    method: setEditTitle,
    entity: User,
  },
  instructions: {
    path: '/instructions',
    page: EntityList,
    method: setTitle,
    entity: Instruction,
  },
  instruction: {
    path: '/instructions/:id',
    page: EntityEdit,
    method: setEditTitle,
    entity: Instruction,
  },
  news: {
    path: '/news',
    page: EntityList,
    method: setTitle,
    entity: News,
  },
  newsItem: {
    path: '/news/:id',
    page: EntityEdit,
    method: setEditTitle,
    entity: News,
  },
  questions: {
    path: '/questions',
    page: EntityList,
    method: setTitle,
    entity: Question,
  },
  question: {
    path: '/questions/:id',
    page: EntityEdit,
    method: setEditTitle,
    entity: Question,
  },
  answers: {
    path: '/answers',
    page: EntityList,
    method: setTitle,
    entity: Answer,
  },
  answer: {
    path: '/answers/:id',
    page: EntityEdit,
    method: setEditTitle,
    entity: Answer,
  },
  articles: {
    path: '/articles',
    page: EntityList,
    method: setTitle,
    entity: Article,
  },
  articler: {
    path: '/articles/:id',
    page: EntityEdit,
    method: setEditTitle,
    entity: Article,
  },
  faq: {
    path: '/faq',
    page: EntityList,
    method: setTitle,
    entity: Faq,
  },
  faqItem: {
    path: '/faq/:id',
    page: EntityEdit,
    method: setEditTitle,
    entity: Faq,
  },
  usergroups: {
    path: '/usergroups',
    page: EntityList,
    method: setTitle,
    entity: UserGroup,
  },
  usergroup: {
    path: '/usergroups/:id',
    page: EntityEdit,
    method: setEditTitle,
    entity: UserGroup,
  },
  staticFaq: {
    path: '/static/faq',
    page: FaqPage,
  },
  policy: {
    path: '/static/policy',
    page: Policy,
  },
};

export default ROUTES;
