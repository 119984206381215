import { Link, useLocation } from 'react-router-dom';
import React, { FC } from 'react';

import UserGroup from '../../entity/UserGroup';
import User from '../../entity/User';
import Question from '../../entity/Question';
import News from '../../entity/News';
import Instruction from '../../entity/Instruction';
import Faq from '../../entity/Faq';
import Article from '../../entity/Article';

import './styles.scss';

const Menu: FC = () => {
  const location = useLocation();

  const isActive = (paramLocation: string) => {
    return location.pathname.indexOf(paramLocation) !== -1 ? ' active' : '';
  };

  return (
    <div className='menu-list'>
      <div className='list-group mb-5'>
        <Link className={`list-group-item list-group-item-action${isActive('/users')}`} to='/users'>
          {User.labelMany}
        </Link>
        <Link
          className={`list-group-item list-group-item-action${isActive('/instructions')}`}
          to='/instructions'
        >
          {Instruction.labelMany}
        </Link>
        <Link className={`list-group-item list-group-item-action${isActive('/news')}`} to='/news'>
          {News.labelMany}
        </Link>
        <Link
          className={`list-group-item list-group-item-action${isActive('/questions')}`}
          to='/questions'
        >
          {Question.labelMany}
        </Link>
        <Link
          className={`list-group-item list-group-item-action${isActive('/articles')}`}
          to='/articles'
        >
          {Article.labelMany}
        </Link>
        <Link
          className={`list-group-item list-group-item-action${isActive('/groups')}`}
          to='/usergroups'
        >
          {UserGroup.labelMany}
        </Link>
        <Link className={`list-group-item list-group-item-action${isActive('/faq')}`} to='/faq'>
          {Faq.labelMany}
        </Link>
      </div>
      <div className='list-group'>
        <Link
          reloadDocument
          className={`list-group-item list-group-item-action${isActive}`}
          to='/logout'
        >
          Выход
        </Link>
      </div>
    </div>
  );
};

export default Menu;
