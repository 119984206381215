import React, { FC, ReactElement, useState, useEffect, ChangeEvent } from 'react';

interface Props {
  label: string;
  name: string;
  value: any;
  questionId: number;
  changeEvent: (name: string, value: any) => void;
}

const FieldAnswerMultiple: FC<Props> = (props) => {
  const [answersCount, setAnswersCount] = useState(0);

  let inputsCreated = 0;

  useEffect(() => {
    if (props.value) {
      setAnswersCount(props.value.length);
    }
  }, [props.value]);

  const customChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    if (target) {
      const value: string = target.value;
      const id = Number(target.name);
      if (id) {
        const answers = props.value || [];

        const newValue = answers.find((answer: any) => answer.id === id);
        if (!newValue) {
          answers.push({
            id: id,
            title: value,
          });
        } else {
          newValue.title = value;
        }

        props.changeEvent(props.name, answers);
      }
    }
  };

  const deleteAnswer = (questionId: number) => {
    const answers = props.value || [];
    const index = answers.findIndex((answer: any) => answer.id === questionId);
    console.log(questionId);
    console.log(index);
    if (index !== -1) {
      setAnswersCount(answersCount - 1);
      delete answers[index];
      props.changeEvent(props.name, answers);
    }
  };

  const renderAnswers = () => {
    if (!props.value) {
      return [];
    }

    const files: Array<ReactElement> = [];
    props.value.forEach((answer: any) => {
      files.push(
        <div className='mb-3' key={answer.id}>
          <div className='row justify-content-between'>
            <div className='col-10'>
              <input
                className='form-control'
                id={`answer-${answer.id}`}
                name={answer.id}
                type='text'
                value={answer.title ? answer.title : ''}
                onChange={customChange}
              />
            </div>
            <div className='col-2 d-grid'>
              <button
                className='btn btn-danger'
                type='button'
                value={answer.id}
                onClick={() => deleteAnswer(answer.id)}
              >
                Удалить
              </button>
            </div>
          </div>
        </div>,
      );
    });

    inputsCreated = files.length;

    return files;
  };

  const renderEmptyAnswers = () => {
    const emptyAnswers: Array<ReactElement> = [];

    while (inputsCreated < answersCount) {
      inputsCreated++;
      emptyAnswers.push(
        <div className='mb-3' key={-inputsCreated}>
          <div className='row'>
            <div className='col-10'>
              <input
                className='form-control'
                name={(-inputsCreated).toString()}
                type='text'
                value={''}
                onChange={customChange}
              />
            </div>
          </div>
        </div>,
      );
    }

    return emptyAnswers;
  };

  return (
    <div className='card mb-3'>
      <div className='card-header'>{props.label}</div>
      <div className='card-body'>
        {renderAnswers()}
        {renderEmptyAnswers()}
      </div>
      <div className='card-footer'>
        <button
          className='btn btn-info'
          type='button'
          onClick={() => setAnswersCount(answersCount + 1)}
        >
          Добавить ответ
        </button>
      </div>
    </div>
  );
};

export default FieldAnswerMultiple;
