import { useNavigate, useLoaderData } from 'react-router-dom';
import React, { FC, useEffect } from 'react';

import request from '../../lib/request';
import { generateTitle } from '../../lib/functions';
import AuthForm from '../../components/AuthForm';

import './styles.scss';

interface Data {
  authed: boolean;
}

export const logoutBeforeLoad = async (): Promise<any> => {
  await request('/api/logout');
  window.history.pushState(null, null, '/');
  return {
    authed: false,
  };
};

export const authBeforeLoad = async (): Promise<any> => {
  document.title = generateTitle('Авторизация');
  const response = await request('/api/check-auth');

  if (response) {
    return response;
  }
  return {
    authed: false,
  };
};

const Auth: FC = () => {
  const navigate = useNavigate();
  const data = useLoaderData() as Data;

  useEffect(() => {
    if (data.authed) {
      navigate('/users');
    }
  }, [data, navigate]);

  return (
    <div className='auth-page__wrapper'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-4'>{data.authed ? null : <AuthForm />}</div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
