import React, { FC, useState, useEffect, ReactElement, useCallback } from 'react';

import request from '../../lib/request';

import './styles.scss';

const InstructionTree: FC = () => {
  const [tree, setTree] = useState([]);

  const loadData = useCallback(() => {
    request('/api/instructions', {
      success: (response) => {
        if (response.data) {
          setTree(response.data);
        }
      },
    });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const renderItem = (item: any, index: number) => {
    return (
      <div className='tree-item' key={index}>
        <div className='item-title'>
          {item.title}
          {item.file ? (
            <span className='bg-success' title='Прикреплен файл'>
              &#9776;
            </span>
          ) : (
            ''
          )}
        </div>
        {item.childrens.length ? renderTree(item.childrens) : ''}
      </div>
    );
  };

  const renderTree = (treeLevelItems: any) => {
    const treeLevel: Array<ReactElement> = [];

    treeLevelItems.forEach((item: any, index: number) => {
      treeLevel.push(renderItem(item, index));
    });

    return <div className='tree-level'>{treeLevel}</div>;
  };

  return (
    <div className='card tree-card'>
      <div className='card-body'>
        <div className='tree-root'>{renderTree(tree)}</div>
      </div>
    </div>
  );
};

export default InstructionTree;
