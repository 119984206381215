import React, { ChangeEvent, FC, ReactElement } from 'react';

interface Props {
  label: string;
  changeEvent: (event: ChangeEvent) => void;
  name: string;
  enum: {
    [key: number]: string;
  };
  value: number;
  placeholder?: string;
  multiple?: boolean;
}

const FieldText: FC<Props> = (props) => {
  const renderOptions = () => {
    const options: Array<ReactElement> = [];

    options.push(
      <option key='initital' value=''>
        Выберите вариант
      </option>,
    );

    Object.entries(props.enum).forEach(([key, value]) => {
      options.push(
        <option key={key} value={key}>
          {value as string}
        </option>,
      );
    });

    return options;
  };

  return (
    <div className='mb-3'>
      <label className='form-label' htmlFor={props.name}>
        {props.label}
      </label>
      <select
        className='form-control'
        id={props.name}
        multiple={props.multiple}
        name={props.name}
        placeholder={props.placeholder}
        value={typeof props.value === 'number' && !Number.isNaN(props.value) ? props.value : ''}
        onChange={props.changeEvent}
      >
        {renderOptions()}
      </select>
    </div>
  );
};

export default FieldText;
