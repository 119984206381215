import { Link } from 'react-router-dom';
import React, { FC, useState, useEffect, ReactElement, ChangeEvent, useCallback } from 'react';

import { Entity, IRoute } from '../../types/types';
import request from '../../lib/request';
import { getFormatedDate } from '../../lib/functions';

import './styles.scss';

interface Props {
  route: IRoute | null;
}

const EntityTable: FC<Props> = (props) => {
  const [data, setData] = useState([] as Array<Entity>);
  const [search, setSearch] = useState('');
  const [viewDisabledItems, setViewDisabledItems] = useState(0);

  const { route } = props;

  const loadData = useCallback(() => {
    const { entity } = route;
    if (!entity) {
      return;
    }

    const options: { method?: 'GET' | 'POST'; body?: BodyInit } = {};

    if (search !== '' || viewDisabledItems !== 2) {
      options.method = 'POST';

      const query: any = {};

      if (search !== '') {
        query.search = search;
      }

      if (viewDisabledItems !== 2) {
        query.disabled = viewDisabledItems;
      }

      options.body = JSON.stringify(query);
    }

    request(`/cms/api${window.location.pathname}/find/0/100`, {
      ...options,
      success: (response) => {
        const filledEntities: any = [];
        if (response.data) {
          response.data.forEach((element: any) => {
            const obj = new entity();
            obj.fill(element);
            filledEntities.push(obj);
          });
        }

        setData(filledEntities);
      },
    });
  }, [route, search, viewDisabledItems]);

  useEffect(() => {
    setData([]);
    loadData();
  }, [route.entity.name, loadData]);

  useEffect(() => {
    loadData();
  }, [search, viewDisabledItems, loadData]);

  const renderTableHead = () => {
    const { entity } = route;
    if (!entity) {
      return;
    }

    const { tableFields, editableField } = entity;

    type Fields = typeof editableField;

    const cells: Array<ReactElement> = [];

    tableFields.forEach((field: any, index: number) => {
      const key = field as keyof Fields;
      cells.push(<td key={index}>{editableField[key].label}</td>);
    });

    cells.push(<td key={cells.length} />);

    return cells;
  };

  const renderTableBody = () => {
    const { entity } = route;
    if (!entity) {
      return;
    }

    const { tableFields, editableField } = entity;

    type Fields = typeof entity;
    type EditableField = typeof editableField;

    const rows: Array<ReactElement> = [];

    data.forEach((obj: any, rowIndex: number) => {
      const cells: Array<ReactElement> = [];

      tableFields.forEach((field: any, cellIndex: number) => {
        const key = field as keyof Fields;
        const fieldKey = field as keyof EditableField;
        const fieldInfo = editableField[fieldKey] as any;
        let value = '';

        switch (editableField[fieldKey].type) {
          case 'select':
            value = fieldInfo.enum[obj[key]];
            break;
          case 'checkbox':
            value = obj[key] ? 'Да' : 'Нет';
            break;
          case 'date':
            value = obj[key] ? getFormatedDate(obj[key]) : '';
            break;
          default:
            value = obj[key] ? obj[key].toString() : '';
        }

        cells.push(<td key={cellIndex}>{value}</td>);
      });

      cells.push(
        <td key={cells.length}>
          <Link to={`${window.location.pathname}/${obj.id}`}>Редактировать</Link>
        </td>,
      );

      rows.push(<tr key={rowIndex}>{cells}</tr>);
    });

    return rows;
  };

  const searchChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    if (target) {
      setSearch(target.value);
    }
  };

  const renderTable = () => {
    const { entity } = route;
    if (!entity) {
      return;
    }
    return (
      <>
        <Link className='btn btn-primary mb-3' to={`${window.location.pathname}/create`}>
          Создать запись &quot;{entity.labelOne}&quot;
        </Link>
        <div className='row'>
          <div className='col-8'>
            <input
              className='form-control mb-4'
              placeholder='Поиск'
              type='text'
              value={search}
              onChange={searchChange}
            />
          </div>
          <div className='col-4'>
            <select
              className='form-control mb-4'
              value={viewDisabledItems}
              onChange={(e) => setViewDisabledItems(Number(e.target.value))}
            >
              <option value={0}>Активные</option>
              <option value={1}>Отключенные</option>
              <option value={2}>Все</option>
            </select>
          </div>
        </div>

        <table className='table entity-table'>
          <thead className='table-dark'>
            <tr>{renderTableHead()}</tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </table>
      </>
    );
  };

  return <div>{renderTable()}</div>;
};

export default EntityTable;
