import React, {
  FC,
  ReactElement,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  useMemo,
} from 'react';

import request from '../../lib/request';
import Question from '../../entity/Question';
import Answer from '../../entity/Answer';

interface Props {
  label: string;
  name: string;
  value: Array<any>;
  articleId: number;
  changeEvent: (name: string, value: any) => void;
}

const DELETE_BUTTON_WIDTH = 36;

const FieldCriteriaMultiple: FC<Props> = (props) => {
  const [criteriaCount, setCriteriaCount] = useState(0);
  const [initialRows, setInitialRows] = useState(0);
  const [questions, setQuestions] = useState([] as Array<Question>);
  const [answers, setAnswers] = useState([] as Array<Answer>);

  const loadData = () => {
    request('/cms/api/questions/find', {
      success: (response) => {
        if (response.data) {
          const filledQuestions: Array<Question> = [];
          response.data.forEach((element: any) => {
            const question = new Question();
            question.fill(element);
            filledQuestions.push(question);
          });

          setQuestions(filledQuestions);
        }
      },
    });
    request('/cms/api/answers/find', {
      success: (response) => {
        if (response.data) {
          const filledAnswers: Array<Answer> = [];
          response.data.forEach((element: any) => {
            const answer = new Answer();
            answer.fill(element);
            filledAnswers.push(answer);
          });

          setAnswers(filledAnswers);
        }
      },
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setCriteriaCount(initialRows);
  }, [initialRows]);

  const customChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    if (target && target.value) {
      const value = Number(target.value);

      const initialRow = Number(target.getAttribute('data-row'));
      const questionId = Number(target.getAttribute('data-questionid'));

      const criterias = props.value || [];

      const newValue = criterias[initialRow]
        ? criterias[initialRow].find((element: any) => element.questionId === questionId)
        : null;

      if (!newValue) {
        let row = initialRow;

        // eslint-disable-next-line
        while (!criterias[row] && row > 0) {
          row--;
        }

        if (initialRow !== row) {
          row++;
        }
        console.log(row);
        if (!criterias[row]) {
          criterias[row] = [];
        }

        criterias[row].push({
          answerId: value,
          questionId: questionId,
        });
      } else {
        newValue.answerId = value;
      }

      props.changeEvent(props.name, criterias);
    }
  };

  const renderAnswersSelect = (questionId: number, rowIndex: number, selectedId: number | null) => {
    if (!answers) {
      return;
    }
    const options: Array<ReactElement> = [];

    options.push(
      <option key='init' value=''>
        Выбрать
      </option>,
    );

    answers.forEach((answer, index) => {
      if (answer.qusetionId === questionId && answer.id) {
        options.push(
          <option key={index} value={answer.id}>
            {answer.title}
          </option>,
        );
      }
    });

    return (
      <select
        className='form-control'
        data-questionid={questionId}
        data-row={rowIndex}
        data-test={selectedId}
        value={selectedId || ''}
        onChange={customChange}
      >
        {options}
      </select>
    );
  };

  const renderCriteriaRow = (rowIndex: number, criterias?: any) => {
    if (!questions || !answers) {
      return <></>;
    }

    const answersElements: Array<ReactElement> = [];

    questions.forEach((question, index) => {
      if (question.id) {
        const criteria = criterias
          ? criterias.find((criteriaItem: any) => criteriaItem.questionId === question.id)
          : null;

        answersElements.push(
          <td key={criteria ? index : -index}>
            {renderAnswersSelect(
              question.id,
              rowIndex,
              criteria && criteria.answerId ? criteria.answerId : null,
            )}
          </td>,
        );
      }
    });

    return (
      <tr data-row-index={`${rowIndex}`} key={rowIndex}>
        {answersElements}
        {renderDeleteButton}
      </tr>
    );
  };

  const renderCiteriasHead = () => {
    const questionsElements: Array<ReactElement> = [];

    questions.forEach((question, index) => {
      questionsElements.push(
        <th
          key={index}
          scope='col'
          style={{
            width: `calc(${100 / questions.length}% - ${DELETE_BUTTON_WIDTH / questions.length}px)`,
          }}
        >
          {question.title}
        </th>,
      );
    });

    questionsElements.push(
      <th key={questions.length} style={{ width: `${DELETE_BUTTON_WIDTH}px` }} />,
    );

    return questionsElements;
  };

  const renderCiterias = () => {
    if (!questions || !answers) {
      return;
    }

    const rows: any = props.value;
    const criterias: Array<ReactElement> = [];
    let inputsCreated = 0;

    if (rows && rows.length) {
      rows.forEach((row: any, index: number) => {
        criterias.push(renderCriteriaRow(index, row));
      });

      if (initialRows !== rows.length) {
        setInitialRows(rows.length);
      }

      inputsCreated = rows.length;
    }

    while (inputsCreated < criteriaCount) {
      criterias.push(renderCriteriaRow(inputsCreated));
      inputsCreated++;
    }

    return criterias;
  };

  const handleClick = useCallback(
    (event: { target: any }) => {
      const deleteFilter = confirm('Вы действительно хотите удалить данный фильтр?');

      if (deleteFilter) {
        const newCriterias = props.value.filter(
          (items: any, index: number) =>
            index !== Number(event.target.closest('tr').dataset.rowIndex),
        );

        props.changeEvent(props.name, newCriterias);
      }
    },
    [props],
  );

  const renderDeleteButton = useMemo(() => {
    return (
      <td>
        <button className='btn btn-danger' onClick={handleClick}>
          X
        </button>
      </td>
    );
  }, [handleClick]);

  const clearCriterias = () => {
    setCriteriaCount(0);
    setInitialRows(0);
    props.changeEvent(props.name, []);
  };

  return (
    <div className='card mb-3'>
      <div className='card-header'>{props.label}</div>
      <div className='card-body'>
        <table className='table'>
          <thead className='table-light'>
            <tr>{renderCiteriasHead()}</tr>
          </thead>
          <tbody>{renderCiterias()}</tbody>
        </table>
      </div>
      <div className='card-footer'>
        <div className='row justify-content-between'>
          <div className='col-auto'>
            <button
              className='btn btn-info'
              type='button'
              onClick={() => setCriteriaCount(criteriaCount + 1)}
            >
              Добавить фильтр
            </button>
          </div>
          <div className='col-auto'>
            <button className='btn btn-danger' type='button' onClick={() => clearCriterias()}>
              Очистить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldCriteriaMultiple;
