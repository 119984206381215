export default class File {
  id: number | null;

  title: string;

  path: string | null;

  disabled: boolean;

  static labelOne = 'Файл';

  static labelMany = 'Файлы';

  fill(data: any) {
    this.id = Number(data.id) || null;
    this.title = data.title || '';
    this.path = data.path || '';
    this.disabled = data.disabled;
  }
}
