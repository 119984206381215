import File from './File';

export default class Instruction {
  id: number | null;

  title: string;

  parent: Instruction | null;

  parentId: number | null;

  childrens: Instruction[];

  file: File;

  fileId: number | null;

  order: number;

  disabled: boolean;

  static labelOne = 'Инструкция С4С';

  static labelMany = 'Инструкции С4С';

  fill(data: any) {
    this.id = Number(data.id) || null;
    this.title = data.title || '';
    this.fileId = data.fileId || null;
    this.parentId = data.parentId || null;
    this.disabled = data.disabled || false;
    this.order = data.order || 0;
  }

  static tableFields = ['title'];

  static editableField = {
    title: {
      type: 'text',
      label: 'Наименование',
      placeholder: 'Инструкция',
    },
    parentId: {
      type: 'selectSource',
      label: 'Родительский элемент',
      source: '/cms/api/instructions/find',
      placeholder: 'Корень',
      defaultValue: 1,
    },
    fileId: {
      type: 'file',
      accept: 'application/pdf',
      extRegEx: 'pdf',
      label: 'Файл',
    },
    order: {
      type: 'text',
      label: 'Приоритет',
      placeholder: 'Приоритет',
    },
    disabled: {
      type: 'checkbox',
      label: 'Отключен',
    },
  };
}
