import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import React from 'react';

import ROUTES from './routes';

const container = document.getElementById('app');

const router = createBrowserRouter(
  Object.values(ROUTES).map((item) => {
    return {
      path: item.path,
      element: <item.page />,
      loader: item.method || undefined,
    };
  }),
);

if (container) {
  createRoot(container).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>,
  );
}
