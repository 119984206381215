import File from './File';

export default class Faq {
  id: number | null;

  question: string;

  answers: string;

  file: File;

  fileId: number | null;

  disabled: boolean;

  fill(data: any) {
    this.id = Number(data.id) || null;
    this.question = data.question || '';
    this.file = data.file || null;
    this.fileId = data.fileId || null;
    this.answers = data.answers || '';
    this.disabled = data.disabled;
  }

  static labelOne = 'FAQ';

  static labelMany = 'FAQ';

  static tableFields = ['question', 'answers'];

  static editableField = {
    question: {
      type: 'text',
      label: 'Вопрос',
      placeholder: 'Вопрос',
    },
    answers: {
      type: 'textarea',
      label: 'Ответ',
    },
    fileId: {
      type: 'file',
      accept: 'image/*',
      extRegEx: '(jpeg|jpg|png|webp)',
      label: 'Файл',
    },
    disabled: {
      type: 'checkbox',
      label: 'Отключен',
    },
  };
}
