import React, { ChangeEvent, FC } from 'react';

interface Props {
  label: string;
  changeEvent: (event: ChangeEvent) => void;
  name: string;
  value: string;
  placeholder?: string;
}

const FieldText: FC<Props> = (props) => {
  return (
    <div className='mb-3'>
      <label className='form-label' htmlFor={props.name}>
        {props.label}
      </label>
      <input
        className='form-control'
        id={props.name}
        name={props.name}
        placeholder={props.placeholder}
        type='text'
        value={typeof props.value !== 'undefined' ? props.value : ''}
        onChange={props.changeEvent}
      />
    </div>
  );
};

export default FieldText;
