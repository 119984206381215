import UserGroup from './UserGroup';

export enum Roles {
  CLIENT,
  ADMIN,
}

export default class User {
  id: number | null;

  email: string;

  userName: string | null;

  password: string;

  role: Roles;

  groups: number[];

  disabled: boolean;

  static labelOne = 'Пользователь';

  static labelMany = 'Пользователи';

  static ROLE_LABELS = {
    [Roles.CLIENT]: 'Клиент',
    [Roles.ADMIN]: 'Администратор',
  };

  getRole() {
    return User.ROLE_LABELS[this.role];
  }

  mapGroups(groups: UserGroup[]) {
    return groups.map((item) => item.id);
  }

  fill(data: any) {
    this.id = Number(data.id) || null;
    this.email = data.email || '';
    this.userName = data.userName || null;
    const role = Number(data.role);
    this.role = role in Roles ? role : Roles.CLIENT;
    this.groups = data.groups ? this.mapGroups(data.groups) : [];
    this.disabled = data.disabled;
  }

  static tableFields = ['email', 'userName', 'role', 'disabled'];

  static editableField = {
    email: {
      type: 'text',
      label: 'E-mail',
      placeholder: 'exmple@domain.com',
    },
    userName: {
      type: 'text',
      label: 'Имя пользователя',
      placeholder: 'exmple',
    },
    role: {
      type: 'select',
      label: 'Роль',
      enum: User.ROLE_LABELS,
    },
    groups: {
      type: 'selectSource',
      multiple: true,
      source: '/cms/api/usergroups/find',
      label: 'Группы',
    },
    disabled: {
      type: 'checkbox',
      label: 'Отключен',
    },
  };
}
