import Select from 'react-select';
import React, { FC, useEffect, useState } from 'react';

import request from '../../lib/request';

interface Options {
  [key: number]: string;
}

interface Option {
  value: number;
  label: string;
}

interface Props {
  label: string;
  changeEvent: (name: string, value: number | Array<number> | null) => void;
  name: string;
  source: string;
  value: number | number[];
  defaultValue?: number;
  placeholder?: string;
  multiple?: boolean;
}

const FieldText: FC<Props> = (props) => {
  const [options, setOptions] = useState({} as Options);

  useEffect(() => {
    const loadData = () => {
      request(props.source, {
        success: (response) => {
          if (response.data) {
            const responseOptions: Options = [];
            response.data.forEach((element: any) => {
              responseOptions[element.id] = element.title || element.email;
            });
            setOptions(responseOptions);
          }
        },
      });
    };

    loadData();
  }, [props.source]);

  const handleChange = (option: Option | Option[]) => {
    let newValue: number | number[];
    if (Array.isArray(option)) {
      newValue = option.map((item) => Number(item.value));
    } else {
      newValue = Number(option.value);
    }

    props.changeEvent(props.name, newValue);
  };

  const getOptions = () => {
    const optionItems: Option[] = [];

    if (props.defaultValue) {
      optionItems.push({
        value: props.defaultValue,
        label: props.placeholder,
      });
    }

    Object.entries(options).forEach(([key, value]) => {
      optionItems.push({
        value: Number(key),
        label: value,
      });
    });

    return optionItems;
  };

  const setValue = () => {
    if (props.multiple) {
      return props.value
        ? // @ts-ignore
          getOptions().filter((item) => props.value.includes(item.value))
        : [];
    }
    if (!props.value) {
      null;
    }

    for (const option of getOptions()) {
      if (option.value === props.value) {
        return option;
      }
    }
  };

  return (
    <div className='mb-3'>
      <label className='form-label' htmlFor={props.name}>
        {props.label}
      </label>
      <Select
        id={props.name}
        isMulti={props.multiple}
        options={getOptions()}
        placeholder={props.placeholder?.toString() || 'Выберите'}
        value={setValue()}
        onChange={handleChange}
      />
    </div>
  );
};

export default FieldText;
