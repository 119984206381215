import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import React, {
  FormEvent,
  ChangeEvent,
  FC,
  useState,
  useEffect,
  ReactElement,
  useCallback,
} from 'react';

import FieldTextarea from '../FieldTextarea';
import FieldText from '../FieldText';
import FieldSelectSource from '../FieldSelectSource';
import FieldSelect from '../FieldSelect';
import FieldFileMultiple from '../FieldFileMultiple';
import FieldFile from '../FieldFile';
import FieldDate from '../FieldDate';
import FieldCriteriaMultiple from '../FieldCriteriaMultiple';
import FieldCheckbox from '../FieldCheckbox';
import FieldAnswerMultiple from '../FieldAnswerMultiple';
import { IRoute } from '../../types/types';
import request from '../../lib/request';
import { getEntityFromRoute } from '../../lib/functions';
import Instruction from '../../entity/Instruction';
import Article from '../../entity/Article';

interface Props {
  route: IRoute;
}

const EntityEditFrom: FC<Props> = (props) => {
  const [inProcess, setInProcess] = useState(false);
  const [obj, setObj] = useState({} as any);

  const [error, setError] = useState('');

  const { id } = useParams();

  const navigate = useNavigate();

  const loadData = useCallback(() => {
    const { entity } = props.route;
    if (!entity || id === 'create') {
      return;
    }

    request(`/cms/api/${window.location.pathname.split('/')[1]}/get/${id}`, {
      success: (response) => {
        const newObj = new entity();
        newObj.fill(response);
        setObj(newObj);
      },
    });
  }, [id, props.route]);

  useEffect(() => {
    loadData();
  }, [props.route.entity, id, loadData]);

  const customChange = (name: string, value: Date | number | Array<number> | string | null) => {
    setError('');
    const newObj = {} as any;
    Object.assign(newObj, obj);
    newObj[name] = value;
    setObj(newObj);
  };

  const criteriasChange = (name: string, value: any) => {
    setError('');
    const newObj = {} as any;
    Object.assign(newObj, obj);
    newObj[name] = value ? value : [];
    setObj(newObj);
  };

  const inputChange = (event: ChangeEvent) => {
    setError('');
    const target = event.target as HTMLInputElement;

    if (target) {
      const name = target.name as string;
      if (name) {
        const newObj = {} as any;
        Object.assign(newObj, obj);
        if (target.type === 'checkbox') {
          newObj[name] = target.checked;
        } else {
          const value: string | boolean | Date = target.value;
          newObj[name] = value ? value : '';
        }
        setObj(newObj);
      }
    }
  };

  const submit = (event: FormEvent) => {
    const { entity } = props.route;

    event.preventDefault();

    setInProcess(true);

    request(
      `/cms/api/${window.location.pathname.split('/')[1]}/save${id !== 'create' ? `/${id}` : ''}`,
      {
        method: 'POST',
        body: JSON.stringify(obj),
        success: (data) => {
          if (data.id) {
            if (id === 'create') {
              navigate(`/${window.location.pathname.split('/')[1]}/${data.id}`);
            }
            setError('');
            if (entity === Article) {
              setObj({} as any);
            }
            loadData();
          }
        },
        done: (data) => {
          setInProcess(false);
          if (data.error) {
            setError(data.error);
            return;
          }
          if (entity === Instruction && id !== 'create') {
            window.location.reload();
          }
        },
      },
    );
  };

  const renderFields = () => {
    const { entity } = props.route;
    if (!entity) {
      return;
    }

    const { editableField } = entity;

    const fieldElements: Array<ReactElement> = [];

    const sortedFields = Object.fromEntries(
      Object.entries(editableField).sort(([, a]: any, [, b]: any) => {
        if (!a.order && !b.order) {
          return 0;
        }
        if (!a.order) {
          return -1;
        }
        if (!b.order) {
          return 1;
        }

        return a.order - b.order;
      }),
    );

    Object.entries(sortedFields).forEach(([key, value]) => {
      const fieldInfo = value as any;
      switch (fieldInfo.type) {
        case 'answerMultiple':
          fieldElements.push(
            <FieldAnswerMultiple
              changeEvent={criteriasChange}
              key={key}
              label={fieldInfo.label}
              name={key}
              questionId={obj.id}
              value={obj[key]}
            />,
          );
          break;
        case 'criteriaMultiple':
          fieldElements.push(
            <FieldCriteriaMultiple
              articleId={obj.id}
              changeEvent={criteriasChange}
              key={key}
              label={fieldInfo.label}
              name={key}
              value={obj[key]}
            />,
          );
          break;
        case 'fileMultiple':
          fieldElements.push(
            <FieldFileMultiple
              accept={fieldInfo.accept}
              changeEvent={customChange}
              extRegEx={fieldInfo.extRegEx}
              key={key}
              label={fieldInfo.label}
              name={key}
              value={obj[key]}
            />,
          );
          break;
        case 'file':
          fieldElements.push(
            <FieldFile
              accept={fieldInfo.accept}
              changeEvent={customChange}
              extRegEx={fieldInfo.extRegEx}
              key={key}
              label={fieldInfo.label}
              name={key}
              value={Number(obj[key])}
            />,
          );
          break;
        case 'date':
          if (typeof obj[key] === 'undefined') {
            customChange(key, new Date());
          }
          fieldElements.push(
            <FieldDate
              changeEvent={customChange}
              key={key}
              label={fieldInfo.label}
              name={key}
              value={obj[key] as Date}
            />,
          );
          break;
        case 'checkbox':
          fieldElements.push(
            <FieldCheckbox
              changeEvent={inputChange}
              key={key}
              label={fieldInfo.label}
              name={key}
              value={obj[key]}
            />,
          );
          break;
        case 'selectSource':
          if (typeof fieldInfo.defaultValue !== 'undefined' && typeof obj[key] === 'undefined') {
            customChange(key, fieldInfo.defaultValue);
          }

          fieldElements.push(
            <FieldSelectSource
              changeEvent={customChange}
              defaultValue={fieldInfo.defaultValue}
              key={key}
              label={fieldInfo.label}
              multiple={fieldInfo.multiple}
              name={key}
              placeholder={fieldInfo.placeholder}
              source={fieldInfo.source}
              value={obj[key]}
            />,
          );
          break;
        case 'select':
          fieldElements.push(
            <FieldSelect
              changeEvent={inputChange}
              enum={fieldInfo.enum}
              key={key}
              label={fieldInfo.label}
              name={key}
              placeholder={fieldInfo.placeholder}
              value={Number(obj[key])}
            />,
          );
          break;
        case 'textarea':
          fieldElements.push(
            <FieldTextarea
              changeEvent={customChange}
              editor={fieldInfo.editor}
              key={key}
              label={fieldInfo.label}
              name={key}
              placeholder={fieldInfo.placeholder}
              value={obj[key]}
            />,
          );
          break;
        default:
          fieldElements.push(
            <FieldText
              changeEvent={inputChange}
              key={key}
              label={fieldInfo.label}
              name={key}
              placeholder={fieldInfo.placeholder}
              value={obj[key]}
            />,
          );
      }
    });

    return fieldElements;
  };

  const renderLabel = () => {
    return `${getEntityFromRoute()?.labelOne} - ${obj.title || obj.email || ''}`;
  };

  return (
    <div className='card'>
      <div className='card-header'>{renderLabel()}</div>
      <div className='card-body'>
        <form onSubmit={submit}>
          {renderFields()}

          {error !== '' ? <div className='alert alert-danger'>{error}</div> : null}

          <div className='mb-3 d-grid'>
            <button className='btn btn-primary' disabled={inProcess}>
              {inProcess ? '...Загрузка' : 'Сохранить'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EntityEditFrom;
