import React, { FC } from 'react';

import './styles.scss';

const Policy: FC = () => {
  return (
    <div className='policy-page__wrapper'>
      <h1 className='policy-page__title'>
        Политика OOO «НЕСТЛЕ РОССИЯ» в отношении обработки персональных данных и сведения о
        реализуемых требованиях к защите персональных данных
      </h1>
      <ol className='policy-page__main-marker'>
        <li className='policy-page__marker'>
          <h2 className='policy-page__sub-title'>Общие положения</h2>
          <ol className='policy-page__main-marker'>
            <li className='policy-page__sub-marker'>Назначение документа</li>
            <p className='policy-page__text'>
              Политика ООО «Нестле Россия» в отношении обработки персональных данных и сведения о
              реализуемых требованиях к защите персональных данных (далее – Политика) является
              основополагающим локальным актом компании, регулирующим вопросы обработки персональных
              данных в ООО «Нестле Россия» (далее – Общество).
            </p>
            <p className='policy-page__text'>
              Настоящая Политика разработана в соответствии с п.2 ч.1 статьи 18.1 Федерального
              закона от 27 июля 2006 года № 152 «О персональных данных» и предназначена для
              ознакомления неограниченного круга лиц, в том числе – путём опубликования на
              веб-сайтах Общества.
            </p>
            <p className='policy-page__text'>
              Политика раскрывает основные категории субъектов, персональные данные которых
              обрабатываются Обществом, цели, способы и принципы обработки персональных данных,
              права субъектов персональных данных, а также перечень мер, применяемых Обществом в
              целях обеспечения безопасности персональных данных при их обработке.
            </p>
            <p className='policy-page__text'>
              Положения настоящей Политики являются основой для разработки локальных актов Общества,
              регламентирующих вопросы обработки и защиты персональных данных в Обществе.
            </p>
            <li className='policy-page__sub-marker'>Область действия</li>
            <p className='policy-page__text'>
              Действие настоящей Политики распространяется на все процессы Общества, в рамках
              которых осуществляется обработка персональных данных как с использованием средств
              вычислительной техники, в том числе с использованием
              информационно-телекоммуникационных сетей, так и без использования таких средств.
            </p>
            <li className='policy-page__sub-marker'>Основные понятия</li>
            <p className='policy-page__text'>
              В настоящей Политике используются следующие основные понятия:
            </p>
            <p className='policy-page__text'>
              Конфиденциальность персональных данных – обязательное для соблюдения оператором или
              иным получившим доступ к персональным данным лицом требование не раскрывать третьим
              лицам и не распространять персональные данные без согласия субъекта персональных
              данных, если иное не предусмотрено федеральным законом.
            </p>
            <p className='policy-page__text'>
              Обработка персональных данных – любое действие (операция) или совокупность действий
              (операций), совершаемых с использованием средств автоматизации или без использования
              таких средств с персональными данными, включая сбор, запись, систематизацию,
              накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
              передачу (распространение, предоставление, доступ), обезличивание, блокирование,
              удаление, уничтожение персональных данных.
            </p>
            <p className='policy-page__text'>
              Оператор – государственный орган, муниципальный орган, юридическое или физическое
              лицо, самостоятельно или совместно с другими лицами организующие и (или)
              осуществляющие обработку персональных данных, а также определяющие цели обработки
              персональных данных, состав персональных данных, подлежащих обработке, действия
              (операции), совершаемые с персональными данными.
            </p>
            <p className='policy-page__text'>
              Персональные данные – любая информация, относящаяся прямо или косвенно к определённому
              или определяемому физическому лицу (субъекту персональных данных).
            </p>
            <p className='policy-page__text'>
              Субъект персональных данных – физическое лицо, которое прямо или косвенно определено
              или определяемо с помощью персональных данных.
            </p>
            <p className='policy-page__text'>
              Трансграничная передача персональных данных – передача персональных данных на
              территорию иностранного государства органу власти иностранного государства,
              иностранному физическому лицу или иностранному юридическому лицу.
            </p>
            <p className='policy-page__text'>
              Общество / Работодатель – Общество с ограниченной ответственностью «Нестле Россия».
            </p>
            <li className='policy-page__sub-marker'>Утверждение и пересмотр</li>
            <p className='policy-page__text'>
              Настоящая Политика вступает в силу с момента ее утверждения Генеральным директором
              Общества и действует до принятия ее новой редакции.
            </p>
            <p className='policy-page__text'>
              Общество проводит пересмотр положений настоящей Политики и их актуализацию по мере
              необходимости, но не реже одного раза в три года, а также:
            </p>
            <ul className='policy-page__custom-marker_type-none'>
              <li className='policy-page__custom-marker'>
                при изменении нормативной базы, затрагивающей принципы и (или) процессы обработки
                персональных данных в Обществе;
              </li>
              <li className='policy-page__custom-marker'>
                ­при создании новых или внесении изменений в существующие процессы обработки
                персональных данных.
              </li>
            </ul>
          </ol>
        </li>
        <li className='policy-page__marker'>
          <h2 className='policy-page__sub-title'>Цели сбора и обработки персональных данных</h2>
          <p className='policy-page__text'>
            С целью поддержания деловой репутации и обеспечения выполнения требований Федерального
            закона от 27.07.2006 № 152-ФЗ «О персональных данных» и принятых в соответствии с ним
            нормативных правовых актов, Общество, являясь оператором персональных данных с
            соответствующими правами и обязанностями, обеспечивает соотвествие обработки
            персональных данных требованиям законодательства Российской Федерации, а также
            надлежащий уровень безопасности обрабатываемых персональных данных.
          </p>
          <p className='policy-page__text'>
            Обработка персональных данных в Общстве осуществляется на законной и справедливой основе
            и ограничивается достижением конкретных, заранее определенных и законных целей.
            Обработке подлежат только персональные данные, которые отвечают целям их обработки.
            Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям
            обработки, избыточность обрабатываемых данных не допускается.
          </p>
          <p className='policy-page__text'>
            При обработке персональных данных обеспечивается точность персональных данных, их
            достаточность и, в необходимых случаях, актуальность по отношению к целям обработки
            персональных данных. Общество принимает и обеспечивает принятие необходимых мер по
            удалению или уточнению неполных или неточных персональных данных.{' '}
          </p>
          <p className='policy-page__text'>
            Хранение персональных данных осуществляется в форме, позволяющей определить субъекта
            персональных данных, не дольше, чем этого требуют цели обработки персональных данных, и
            они подлежат уничтожению по достижению целей обработки или в случае утраты необходимости
            в их достижении, если иное не предусмотрено законодательством.
          </p>
          <p className='policy-page__text'>
            Общество в рамках выполнения своей основной деятельности осуществляет обработку
            персональных данных своих работников, включая бывших работников (Работников); членов
            семей работников; соискателей вакантных должностей Общества, а также соискателей во
            внешнем кадровом резерве (соискателей) и лиц, предоставляющих рекомендации соискателям
            на вакантные должности и включаемым во внешний кадровый резерв (рекомендателей);
            контрагентов – физических лиц и индивидуальных предпринимателей, представителей
            контрагентов – юридических лиц и индивидуальных предпринимателей , (представителей
            контрагентов); конечных потребителей продукции под товарными знаками, права на
            использование которых на территории РФ принадлежит Обществу, участников рекламных и иных
            мероприятий, направленных на продвижение продукции Nestle ®, организуемых и (или)
            проводимых Обществом или по его поручению (конечных потребителей); медицинских
            работников; представителей субъектов персональных данных, не являющихся работниками
            Общества; посетителей охраняемых помещений Общества (посетителей); пользователей сайтов
            Общества в сети Интернет, направляющих сообщения в Общество посредством веб-форм
            обратной связи, в том числе покупателей интернет-магазинов (пользователей сайтов), и
            незарегистрированных (неавторизованных) посетителей сайтов Общества в сети Интернет
            (посетителей сайтов).
          </p>
          <p className='policy-page__text'>
            Обработка персональных данных вышеперечисленных субъектов персональных данных ведется
            Обществом в следующих целях:
          </p>
          <ul className='policy-page__custom-marker_type-none'>
            <li className='policy-page__custom-marker'>
              ­персональных данных работников Общества, в том числе бывших, с которыми трудовые
              договоры прекращены (расторгнуты):
            </li>
            <ul data-type='circle'>
              <li className='policy-page__text'>
                заключения, исполнения, сопровождения, изменения, расторжения трудовых договоров,
                которые являются основанием для возникновения или прекращения трудовых отношений
                между работниками и Работодателем;
              </li>
              <li className='policy-page__text'>
                исполнения Работодателем обязательств, предусмотренных локальными нормативными
                актами, трудовыми договорами, законодательством, в том числе в целях ведения
                кадрового и бухгалтерского учета, составления налоговой и другой статистической
                отчетности, расчета и выплаты заработной платы, иных вознаграждений, расчета и
                перечисления налогов и отчислений;
              </li>
              <li className='policy-page__text'>
                содействия Работникам в обучении и продвижения по работе/карьерной лестнице;
              </li>
              <li className='policy-page__text'>
                обеспечения личной безопасности Работников, контроля количества и качества
                выполняемой Работниками работы, обеспечения сохранности имущества, проведение
                обязательных медицинских осмотров;
              </li>
              <li className='policy-page__text'>
                предоставления Работникам дополнительных услуг за счет Работодателя (платежные
                карты, страхование за счет Работодателя, негосударственное пенсионное обеспечение,
                обеспечение командировок, оплата услуг мобильной связи, предоставление парковочного
                места и пр.); выполнения требований нормативных правовых актов органов
                государственного статистического учета;
              </li>
              <li className='policy-page__text'>
                обеспечения архивного хранения кадровых, бухгалтерских и других документов
                Работников в соответствии с законодательством РФ.
              </li>
            </ul>
            <li className='policy-page__custom-marker'>
              персональных данных членов семей работников:
            </li>
            <ul data-type='circle'>
              <li className='policy-page__text'>
                предоставления Работникам льгот и гарантий, предусмотренных законодательством или
                локальными норматиными актами Работодателя;
              </li>
              <li className='policy-page__text'>
                выполнения требований Трудового кодекса РФ об информировании членов семей Работников
                о тяжелых несчастных случаях или несчастных случаях со смертельным исходом;
              </li>
              <li className='policy-page__text'>
                заключения, изменения, расторжения договоров добровольного медицинского страхования
                членов семей Работников;
              </li>
              <li className='policy-page__text'>
                выполнения требований нормативных правовых актов органов государственного
                статистического учета.
              </li>
            </ul>
            <li className='policy-page__custom-marker'>
              ­персональных данных соискателей на вакантные должности в Обществе, а также
              соискателей во внешнем кадровом резерве Общества:
            </li>
            <ul data-type='circle'>
              <li className='policy-page__text'>
                рассмотрения резюме и подбора кандидатов на вакантные должности для дальнейшего
                трудоустройства;
              </li>
              <li className='policy-page__text'>ведения внешнего кадрового резерва.</li>
            </ul>
            <li className='policy-page__custom-marker'>­персональных данных рекомендателей:</li>
            <ul data-type='circle'>
              <li className='policy-page__text'>
                получения информации об опыте, навыках и личностных качествах соискателя;
              </li>
              <li className='policy-page__text'>
                подтверждения информации и данных, содержащихся в полученных от соискателя
                письменных рекомендациях, а также получения иной информации о соискателе.
              </li>
            </ul>
            <li className='policy-page__custom-marker'>
              персональных данных представителей контрагентов:
            </li>
            <ul data-type='circle'>
              <li className='policy-page__text'>
                выполнения норм Гражданского кодекса РФ, регулирующих договорную работу, заключения,
                изменения, расторжения договоров, а также выполнения обязательств по заключенным
                договорам;
              </li>
              <li className='policy-page__text'>
                соблюдения и (или) исполнения процедур и действий, предусмотренных локальными
                нормативными актами и (или) законодательством Российской Федерации, в том числе в
                целях ведения бухгалтерского учета, составления налоговой отчетности.{' '}
              </li>
            </ul>
            <li className='policy-page__custom-marker'>
              персональных данных конечных потребителей:
            </li>
            <ul data-type='circle'>
              <li className='policy-page__text'>
                сбора информации о потребителях продукции Nestle ®, мнении потребителей о продукции
                Nestle ®, в частности о ее качестве;
              </li>
              <li className='policy-page__text'>
                с целью распространения информации о продукции Nestle ®;
              </li>
              <li className='policy-page__text'>
                с целью информирования о мероприятиях, проводимых и (или) организуемых Общество или
                по его поручению;
              </li>
              <li className='policy-page__text'>проведения промо-акций и конкурсов.</li>
            </ul>
            <li className='policy-page__custom-marker'>
              персональных данных медицинских работников:
            </li>
            <ul data-type='circle'>
              <li className='policy-page__text'>
                систематизации данных о медицинских работниках для формирования статистики о
                профессиональном мнении и предпочтениях видов и источников коммуникации
                профессионального назначения;
              </li>
              <li className='policy-page__text'>
                проведения маркетинговых исследований с целью изучения отношения медицинских
                работников к продукции, маркированной товарными знаками, права на использование
                которых на территории РФ принадлежит Обществу;{' '}
              </li>
              <li className='policy-page__text'>
                своевременного информирования медицинских работников о показателях качества,
                эффективности и безопасности продукции, маркированной товарными знаками, права на
                использование которых на территории РФ принадлежит Обществу, а также предоставления
                иной информации об указанной продукции;
              </li>
              <li className='policy-page__text'>
                предоставления информации научного и образовательного характера, приглашения к
                участию в конференциях и исследованиях.
              </li>
            </ul>
            <li className='policy-page__custom-marker'>
              ­персональных данных представителей субъектов – выполнения Обществом действий по
              поручению Представителей субъектов персональных данных;
            </li>
            <li className='policy-page__custom-marker'>
              ­персональных данных посетителей - обеспечения возможности прохода в охраняемые
              помещения Общества лиц, не имеющих постоянных пропусков, контроля их убытия из
              охраняемых помещений;
            </li>
            <li className='policy-page__custom-marker'>
              ­персональных данных пользователей сайтов:
            </li>
            <ul data-type='circle'>
              <li className='policy-page__text'>
                продажи, приобретения продукции Nestle в интернет-магазинах, доставки ее
                покупателям, гарантийного и постгарантийного обслуживания, возврата или замены
                продукции Nestle ®;
              </li>
              <li className='policy-page__text'>
                предоставления возможности воспользоваться формами обратной связи с Обществом,
                подписываться на новостные и маркетинговые рассылки.{' '}
              </li>
            </ul>
            <li className='policy-page__custom-marker'>
              ­персональных данных посетителей сайтов – информирования о деятельности Общества,
              производимой и реализуемой Обществом продукции, предоставляемых Обществом услугах, о
              мероприятиях, проводимых и (или) организуемых Обществом или по его поручению, сбора
              информации о предпочтениях в области продукции Общества посетилей сайтов.
            </li>
          </ul>
        </li>
        <li className='policy-page__marker'>
          <h2 className='policy-page__sub-title'>
            Правовые основания обработки персональных данных
          </h2>
          <p className='policy-page__text'>
            Обработка персональных данных Обществом допускается в следующих случаях:
          </p>
          <ul className='policy-page__custom-marker_type-none'>
            <li className='policy-page__custom-marker'>
              ­при наличии согласия Субъекта персональных данных на обработку его персональных
              данных;{' '}
            </li>
            <li className='policy-page__custom-marker'>
              ­обработка необходима для достижения целей, предусмотренных законодательством, а также
              для осуществления и выполнения возложенных законодательством на Общество функций,
              полномочий и обязанностей;{' '}
            </li>
            <li className='policy-page__custom-marker'>
              ­для заключения договора по инициативе Субъекта персональных данных и исполнения
              договора, стороной которого или выгодоприобретателем по которому является Субъект
              персональных данных; такими договорами, без ограничения, являются трудовые договоры с
              работниками, договоры гражданско-правового характера с контрагентами – физическими
              лицами;{' '}
            </li>
            <li className='policy-page__custom-marker'>
              ­обработка необходима для осуществления прав и законных интересов Общества и/или
              третьих лиц либо для достижения общественно значимых целей при условии, что при этом
              не нарушаются права и свободы Субъектов персональных данных;{' '}
            </li>
            <li className='policy-page__custom-marker'>
              ­обработка осуществляется в статистических или иных исследовательских целях при
              условии обязательного обезличивания персональных данных;
            </li>
            <li className='policy-page__custom-marker'>
              ­обработка персональных данных, разрешенных Субъектом персональных данных для
              распространения;{' '}
            </li>
            <li className='policy-page__custom-marker'>
              ­персональные данные подлежат опубликованию или обязательному раскрытию в соответствии
              с законодательством.
            </li>
          </ul>
        </li>
        <li className='policy-page__marker'>
          <h2 className='policy-page__sub-title'>
            Порядок и условия обработки персональных данных
          </h2>
          <p className='policy-page__text'>
            Общество при сборе персональных данных обеспечивает запись, систематизацию, накопление,
            хранение, уточнение (обновление, изменение) и извлечение персональных данных с
            использованием баз данных, находящихся на территории Российской Федерации.
          </p>
          <p className='policy-page__text'>
            Общество в ходе своей деятельности поручает обработку персональных данных третьим лицам
            с согласия Субъектов персональных данных, если иное не предусмотрено действующим
            законодательством Российской Федерации, при обязательном условии соблюдения лицом,
            осуществляющим обработку персональных данных по поручению Общества, принципов и правил
            обработки и обеспечения безопасности персональных данных, установленных
            законодательством Российской Федерации.{' '}
          </p>
          <p className='policy-page__text'>
            В поручении на обработку персональных данных в обязательном порядке определяются:
          </p>
          <ul className='policy-page__custom-marker_type-none'>
            <li className='policy-page__custom-marker'>
              ­обязанность соблюдать принципы и правила обработки персональных данных,
              предусмотренные Федеральным законом «О персональных данных»;
            </li>
            <li className='policy-page__custom-marker'>
              ­перечень действий с персональных данных, которые будут совершаться лицом,
              осуществляющим обработку персональных данных, при этом перечень действий не должен
              противоречить целям и действиям, заявленным перед Субъектом персональных данных в
              договоре с оператором, согласии и т. п. документах;
            </li>
            <li className='policy-page__custom-marker'>
              ­цели обработки при этом цели не должны противоречить целям, заявленным перед
              Субъектом персональных данных в договоре с оператором, согласии и т. п. документах;
            </li>
            <li className='policy-page__custom-marker'>
              ­обязанность такого лица соблюдать конфиденциальность персональных данных и
              обеспечивать безопасность персональных данных при их обработке;
            </li>
            <li className='policy-page__custom-marker'>
              ­требования к защите персональных данных в соответствии со статьей 19 Федерального
              закона «О персональных данных» (требования по защите, предъявляемые к лицу,
              осуществляющему обработку, не должны быть выше требований, выполняемых самим
              оператором).
            </li>
          </ul>
          <p className='policy-page__text'>
            Общество не размещает персональные данные Субъекта персональных данных в общедоступных
            источниках и не распространяет персональные данные неограниченному кругу лиц без
            предварительного согласия Субъекта персональных данных.
          </p>
          <p className='policy-page__text'>
            Общество в ходе своей деятельности осуществляет трансграничную передачу персональных
            данных юридическим лицам на территории иностранных государств. При этом вопросы
            обеспечения адекватной защиты прав Субъектов персональных данных и обеспечения
            безопасности их персональных данных при трансграничной передаче являются приоритетом для
            Общества, решение которых реализуется в соответствии с законодательством Российской
            Федерации в области персональных данных.
          </p>
          <p className='policy-page__text'>
            Трансграничная передача персональных данных на территорию иностранных государств, не
            обеспечивающих адекватной защиты прав Субъектов персональных данных, осуществляется
            только в случаях наличия согласия в письменной форме Субъекта персональных данных на
            трансграничную передачу его персональных данных; исполнения договора, стороной которого
            является Субъект персональных данных и который прямо предусматривает необходимость
            трансграничной передачи, а также в иных предусмотренных законодательством случаях.{' '}
          </p>
          <p className='policy-page__text'>
            В целях обеспечения адекватной защиты персональных данных Общество проводит оценку
            вреда, который может быть причинен Субъектам персональных данных в случае нарушения
            безопасности их персональных данных, а также определяет актуальные угрозы безопасности
            персональных данных при их обработке в информационных системах персональных данных.
          </p>
          <p className='policy-page__text'>
            С целью обеспечения безопасности персональных данных при их обработке Общество принимает
            необходимые и достаточные правовые, организационные и технические меры для защиты
            персональных данных от неправомерного или случайного доступа к ним, уничтожения,
            изменения, блокирования, копирования, предоставления, распространения персональных
            данных, а также от иных неправомерных действий в отношении персональных данных.
          </p>
          <p className='policy-page__text'>
            Общество добивается того, чтобы все реализуемые мероприятия по организационной и
            технической защите персональных данных осуществлялись на законных основаниях, в том
            числе в соответствии с требованиями законодательства Российской Федерации по вопросам
            обработки персональных данных.
          </p>
          <p className='policy-page__text'>
            Руководство Общества осознает важность и необходимость обеспечения безопасности
            персональных данных и поощряет постоянное совершенствование системы защиты персональных
            данных, обрабатываемых в рамках выполнения основной деятельности компании.
          </p>
          <p className='policy-page__text'>
            В Обществе назначены лица, ответственные за организацию обработки и обеспечение
            безопасности персональных данных.
          </p>
          <p className='policy-page__text'>
            Каждый новый работник Общества, непосредственно осуществляющий обработку персональных
            данных, подлежит ознакомлению с требованиями законодательства Российской Федерации по
            обработке и обеспечению безопасности персональных данных, с настоящей Политикой и
            другими локальными актами по вопросам обработки и реализации требований к защите
            персональных данных и несет ответственность за их соблюдение.
          </p>
          <p className='policy-page__text'>
            Хранение персональных данных осуществляется Обществом в форме, позволяющей определить
            Субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных
            данных, если срок хранения персональных данных не установлен федеральным законом,
            договором, стороной которого, выгодоприобретателем или поручителем по которому является
            Субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению по
            достижении целей обработки или в случае утраты необходимости в достижении этих целей,
            если иное не предусмотрено федеральным законом.
          </p>
        </li>
        <li className='policy-page__marker'>
          <h2 className='policy-page__sub-title'>
            Обработка запросов Субъектов персональных данных
          </h2>
          <p className='policy-page__text'>
            Для обеспечения соблюдения установленных законодательством прав Субъектов персональных
            данных в Обществе разработан и введён порядок работы с обращениями и запросами Субъектов
            персональных данных, а также порядок предоставления Субъектам персональных данных
            информации, установленной законодательством РФ в области персональных данных.
          </p>
          <p className='policy-page__text'>
            Данный порядок обеспечивает соблюдение следующих прав Субъекта персональных данных:
          </p>
          <ul className='policy-page__custom-marker_type-none'>
            <li className='policy-page__custom-marker'>
              ­право на получение информации, касающейся обработки персональных данных
              соответствующего Субъекта персональных данных, в том числе содержащей:
            </li>
            <ul data-type='circle'>
              <li className='policy-page__text'>
                подтверждение факта обработки персональных данных;
              </li>
              <li className='policy-page__text'>
                правовые основания и цели обработки персональных данных;
              </li>
              <li className='policy-page__text'>
                применяемые Обществом способы обработки персональных данных;
              </li>
              <li className='policy-page__text'>
                наименование и место нахождения Общества, сведения о лицах (за исключением
                работников Общества), которые имеют доступ к персональных данных или которым могут
                быть раскрыты персональные данные на основании договора с Обществом или на основании
                федерального закона;{' '}
              </li>
              <li className='policy-page__text'>
                обрабатываемые персональные данные, относящиеся к соответствующему Субъекту
                персональных данных, источник их получения, если иной порядок представления таких
                персональных данных не предусмотрен федеральным законом;
              </li>
              <li className='policy-page__text'>
                сроки обработки персональных данных, в том числе сроки их хранения;
              </li>
              <li className='policy-page__text'>
                порядок осуществления Субъектом персональных данных прав, предусмотренных
                Федеральным законом «О персональных данных»;
              </li>
              <li className='policy-page__text'>
                информацию об осуществляемой или о предполагаемой трансграничной передаче
                персональных данных;
              </li>
              <li className='policy-page__text'>
                наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку
                персональных данных по поручению Общества, если обработка поручена или будет
                поручена такому лицу;
              </li>
              <li className='policy-page__text'>
                иные сведения, предусмотренные Федеральным законом «О персональных данных» или
                другими требованиями законодательства в области персональных данных.
              </li>
            </ul>
            <li className='policy-page__custom-marker'>
              ­право на уточнение, блокирование или уничтожение своих персональных данных, если
              персональные данные являются неполными, устаревшими, неточными, незаконно полученными
              или не являются необходимыми для заявленной цели обработки, а также принимать
              предусмотренные законодательством РФ в области персональных данных меры по защите
              своих прав.
            </li>
          </ul>
          <p className='policy-page__text'>
            Запрос Субъекта персональных данных должен содержать номер основного документа,
            удостоверяющего личность Субъекта персональных данных или его законного представителя,
            сведения о дате выдачи указанного документа и выдавшем его органе, сведения,
            подтверждающие участие Субъекта персональных данных в отношениях с Обществом (номер
            договора и дата заключения договора с Обществом, и (или) иные сведения, копию
            (отсканированную фотографию) сообщения в форме письма или в электронной форме, в виде
            смс-сообщения, полученных от Общества и т.п.), либо сведения, иным образом
            подтверждающие факт обработки персональных данных Обществом, подпись Субъекта
            персональных данных или его представителя, дату обращения.
          </p>
          <p className='policy-page__text'>
            Работники Общества не имеют право отвечать на вопросы, связанные с передачей или
            разглашением персональных данных по телефону или факсу в связи с тем, что в таком случае
            нет возможности идентифицировать личность обращающегося человека.
          </p>
          <p className='policy-page__text'>
            Запросы Субъектов персональных данных должны быть направлены по почте по адресу: ООО
            «Нестле Россия», 115054, г. Москва, Павелецкая пл., д.2, стр.1.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default Policy;
