import { Scrollbars } from 'react-custom-scrollbars';
import React, { ChangeEvent, FC, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import './FieldTextarea.scss';

interface Props {
  label: string;
  changeEvent: (name: string, value: string) => void;
  name: string;
  value: string;
  editor?: boolean;
  placeholder?: string;
}

const FieldTextarea: FC<Props> = (props) => {
  const [isPreview, setIsPreview] = useState(false);

  const changeHandler = (newValue: string) => {
    props.changeEvent(props.name, newValue);
  };

  const TextareaChangeHandler = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    if (target) {
      const newValue = target.value;
      props.changeEvent(props.name, newValue);
    }
  };

  const onButtonClick = () => {
    setIsPreview(!isPreview);
  };

  const addPreviewButton = (editor: any) => {
    editor.ui.registry.addButton('preview', {
      text: 'Предпросмотр',
      onAction: onButtonClick,
    });
  };

  const renderEditorAndPreview = () => {
    const editor = (
      <Editor
        init={{
          setup: addPreviewButton,
          min_height: isPreview ? 844 : 400,
          max_height: 844,
          menubar: false,
          plugins: ['link', 'autolink', 'autoresize', 'code', 'image', 'lists', 'media'],
          toolbar:
            'blocks | ' +
            'bold italic | ' +
            'forecolor backcolor | ' +
            'alignleft aligncenter ' +
            'alignright alignjustify | ' +
            'bullist numlist | ' +
            'removeformat | ' +
            'link image media | ' +
            'code | preview',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          language: 'ru',
          promotion: false,
          toolbar_mode: 'wrap',
        }}
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        value={typeof props.value !== 'undefined' ? props.value : ''}
        onEditorChange={changeHandler}
      />
    );

    if (isPreview) {
      return (
        <div className='editor-container'>
          <div>{editor}</div>
          <div className='editor-preview'>
            <Scrollbars autoHide>
              <div
                className='editor-preview__content'
                dangerouslySetInnerHTML={{ __html: props.value }}
              />
            </Scrollbars>
          </div>
        </div>
      );
    }

    return editor;
  };

  return (
    <div className='mb-3'>
      <label className='form-label' htmlFor={props.name}>
        {props.label}
      </label>
      {props.editor ? (
        renderEditorAndPreview()
      ) : (
        <textarea
          className='form-control'
          id={props.name}
          name={props.name}
          placeholder={props.placeholder}
          value={typeof props.value !== 'undefined' ? props.value : ''}
          onChange={TextareaChangeHandler}
        />
      )}
    </div>
  );
};

export default FieldTextarea;
