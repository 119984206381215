import User from './User';

export default class UserGroup {
  id: number | null;

  title: string;

  users: number[];

  disabled: boolean;

  static labelOne = 'Группа';

  static labelMany = 'Группы';

  mapUsers = (users: User[]) => {
    return users.map((item) => item.id);
  };

  fill(data: any) {
    this.id = Number(data.id) || null;
    this.title = data.title || '';
    this.users = data.users ? this.mapUsers(data.users) : [];
    this.disabled = data.disabled;
  }

  static tableFields = ['title'];

  static editableField = {
    title: {
      type: 'text',
      label: 'Название',
      placeholder: 'Название',
    },
    users: {
      type: 'selectSource',
      multiple: true,
      source: '/cms/api/users/find',
      label: 'Пользователи',
    },
    disabled: {
      type: 'checkbox',
      label: 'Отключен',
    },
  };
}
