import DatePicker from 'react-datepicker';
import React, { FC } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

interface Props {
  label: string;
  changeEvent: (name: string, value: Date) => void;
  name: string;
  value: Date;
  placeholder?: string;
}

const FieldText: FC<Props> = (props) => {
  const handleChange = (value: Date) => {
    props.changeEvent(props.name, value);
  };

  if (!props.value) {
    props.changeEvent(props.name, new Date());
  }

  return (
    <div className='mb-3'>
      <label className='form-label' htmlFor={props.name}>
        {props.label}
      </label>
      <DatePicker
        showTimeInput
        className='form-control'
        dateFormat='dd.MM.yyyy HH:mm'
        selected={props.value ? props.value : new Date()}
        timeInputLabel='Время:'
        onChange={handleChange}
      />
    </div>
  );
};

export default FieldText;
