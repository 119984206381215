import UserGroup from './UserGroup';
import File from './File';

export default class ContentEntity {
  id: number | null;

  title: string;

  content: string;

  mainImage: File;

  mainImageId: number | null;

  files: File[];

  fileIds: number[];

  groups: number[];

  disabled: boolean;

  mapGroups(groups: UserGroup[]) {
    return groups.map((item) => item.id);
  }

  fill(data: any) {
    this.id = Number(data.id) || null;
    this.title = data.title || '';
    this.content = data.content || '';
    this.mainImageId = data.mainImageId || null;
    this.fileIds = data.fileIds || [];
    this.disabled = data.disabled;
    this.groups = data.groups ? this.mapGroups(data.groups) : [];
  }

  static tableFields = ['title', 'publishDate'];

  static editableField = {
    title: {
      type: 'text',
      label: 'Заголовок',
      placeholder: 'Заголовок',
      order: 10,
    },
    groups: {
      type: 'selectSource',
      multiple: true,
      source: '/cms/api/usergroups/find',
      label: 'Группы',
      order: 15,
    },
    mainImageId: {
      type: 'file',
      accept: 'image/*',
      label: 'Изображение',
      order: 30,
    },
    content: {
      type: 'textarea',
      label: 'Содержимое',
      placeholder: 'Текст новости',
      order: 40,
      editor: true,
    },
    fileIds: {
      type: 'fileMultiple',
      accept: 'image/*, application/pdf',
      label: 'Файлы',
      order: 50,
    },
    disabled: {
      type: 'checkbox',
      label: 'Отключен',
      order: 60,
    },
  };
}
