import React, { FC, MouseEvent, useState } from 'react';

import './styles.scss';

interface Props {
  question: string;
  answer: string;
  imageUrl: string | null;
  imageAlt: string | null;
}

const FaqItem: FC<Props> = (props) => {
  const [isActive, setIsActive] = useState(false);

  const getClass = () => {
    let itemClass = 'mb-3 faq-item';

    if (isActive) {
      itemClass += ' active';
    }

    return itemClass;
  };

  const messageToApp = (e: MouseEvent) => {
    const src = (e.target as HTMLImageElement).src;
    // @ts-ignore
    if (src && window.ReactNativeWebView?.postMessage) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'fullScreen', src }));
    }
  };

  return (
    <div className={getClass()}>
      <div className='faq-item__question' onClick={() => setIsActive(!isActive)}>
        {props.question}
        <div className='faq-item__toggler' />
      </div>
      <div className='faq-item__answer'>
        <div className='faq-item__answer__text'>{props.answer}</div>
        {props.imageUrl && (
          <div className='faq-item__answer__image-container'>
            <img
              alt={props.imageAlt}
              className='faq-item__answer__image'
              src={props.imageUrl}
              onClick={messageToApp}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FaqItem;
