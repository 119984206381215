interface RequestOptions {
  body?: BodyInit;
  isBlob?: boolean;
  headers?: Record<string, string>;
  successStatuses?: number[];
  isAbsolutePath?: boolean;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  done?: (response: any, status: number) => void;
  failed?: (response: any, status: number) => void;
  success?: (response: any, status: number) => void;
}

const defaultRequestOptions: RequestOptions = {
  method: 'GET',
  successStatuses: [200, 204],
};

const prepareOptions = (originPath: string, originOptions: RequestOptions) => {
  return {
    pathname: originPath,
    options: originOptions,
  };
};

const request = async (
  originPath: string,
  originOptions: RequestOptions = defaultRequestOptions,
) => {
  const { pathname, options } = prepareOptions(originPath, originOptions);

  const { done, body, failed, headers, success, method = 'GET', successStatuses } = options;

  const path = pathname;
  const requestOptions: RequestInit = { method };

  if (body) requestOptions.body = body;
  requestOptions.headers = {
    'Content-Type': 'application/json',
    ...(headers || {}),
  };

  let status;

  try {
    let response: Blob | object = { status: 'Development request error' };
    const serverRequest = await fetch(path, requestOptions);

    const statuses: number[] = successStatuses || [200];

    status = serverRequest.status;

    if (status === 204) {
      response = {};
    } else if (options.isBlob) {
      response = await serverRequest.blob();
    } else {
      response = await serverRequest.json();
    }

    if (statuses.indexOf(status) !== -1) {
      if (success) await success(response, status);
    } else {
      if (failed) return failed(response, status);
      console.error(path, status);
    }

    if (done) done(response, status);
    return response;
  } catch (error) {
    // console.log(error);
    console.log(`ERROR API REQUEST: ${path} ${status}`);
    if (done) done({ error: 'error fetch' }, 500);
  }
};

export default request;
