import React, { FC } from 'react';

import Menu from '../Menu';

import './styles.scss';

interface Props {
  children: JSX.Element | string | undefined | null;
}

const Container: FC<Props> = (props) => {
  return (
    <div className='container'>
      <div className='page-wrapper'>
        <Menu />
        <div className='content'>{props.children}</div>
      </div>
    </div>
  );
};

export default Container;
