import Question from './Question';

export default class Answer {
  id: number | null;

  title: string;

  qusetion: Question | null;

  qusetionId: number | null;

  disabled: boolean;

  static labelOne = 'Ответ';

  static labelMany = 'Ответы';

  fill(data: any) {
    this.id = Number(data.id) || null;
    this.title = data.title || '';
    this.qusetionId = data.qusetionId || null;
    this.disabled = data.disabled;
  }

  static tableFields = ['title'];

  static editableField = {
    title: {
      type: 'text',
      label: 'Ответ',
      placeholder: 'Ответ',
    },
    qusetionId: {
      type: 'selectSource',
      source: '/cms/api/questions/find',
      label: 'Вопрос',
    },
    disabled: {
      type: 'checkbox',
      label: 'Отключен',
    },
  };
}
