import { Entity, IRoute } from '../types/types';
import ROUTES from '../routes';

export function generateTitle(text: string) {
  return `Nestle - ${text}`;
}

const PAD = (string: number) => string.toString().replace(/^(\d)$/, '0$1');

export const getFormatedDate = (date: Date) => {
  return `${PAD(date.getDate())}.${PAD(date.getMonth() + 1)}.${date.getFullYear()}`;
};

export const getEntityFromRoute = (): Entity | null => {
  let entity: Entity | null = null;
  Object.values(ROUTES).forEach((element) => {
    if (window.location.pathname.indexOf(element.path) !== -1 && element.entity) {
      entity = element.entity;
    }
  });

  return entity;
};

export const getRouteFromPathname = (): IRoute | null => {
  let route: IRoute | null = null;
  Object.values(ROUTES).forEach((element) => {
    if (window.location.pathname.indexOf(element.path) !== -1 && element.entity) {
      route = element;
    }
  });

  return route;
};
