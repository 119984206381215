import React, { FC, useState, useEffect } from 'react';

import { generateTitle, getEntityFromRoute, getRouteFromPathname } from '../../lib/functions';
import EntityTable from '../../components/EntityTable';
import Container from '../../components/Container';

export const setTitle = async (): Promise<any> => {
  const entity = getEntityFromRoute();
  if (entity) {
    document.title = generateTitle(entity.labelMany);
  }
  return null;
};

const EntityList: FC = () => {
  const [route, setRoute] = useState(getRouteFromPathname());

  const { entity } = getRouteFromPathname();

  useEffect(() => {
    setRoute(getRouteFromPathname());
  }, [setRoute, entity]);

  return (
    <Container>
      <EntityTable route={route} />
    </Container>
  );
};

export default EntityList;
