import React, { FC, ReactElement, useState, useEffect } from 'react';

import FieldFile from '../FieldFile';

import '../FieldFile/styles.scss';

interface Props {
  accept?: string;
  label: string;
  name: string;
  value: Array<number>;
  extRegEx?: string;
  changeEvent: (name: string, value: Array<number>) => void;
}

const FieldFileMultiple: FC<Props> = (props) => {
  const [filesCount, setFilesCount] = useState(0);

  let inputsCreated = 0;

  useEffect(() => {
    if (props.value) {
      setFilesCount(props.value.length);
    }
  }, [props.value]);

  const customChange = (name: string, value: number, remove?: number) => {
    const fileIds = props.value;

    if (typeof remove !== 'undefined') {
      setFilesCount(filesCount - 1);
      const index = fileIds.indexOf(remove);
      if (index !== -1) {
        delete fileIds[index];
      }
    } else {
      if (!fileIds.includes(value as number)) {
        fileIds.push(value as number);
      }
    }
    props.changeEvent(name, fileIds);
  };

  const renderFiles = () => {
    if (!props.value) {
      return [];
    }

    const files: Array<ReactElement> = [];
    props.value.forEach((fileId: number, index: number) => {
      files.push(
        <FieldFile
          accept={props.accept}
          changeEvent={customChange}
          key={fileId}
          label={`Файл - ${index + 1}`}
          name={props.name}
          value={fileId}
        />,
      );
    });

    inputsCreated = files.length;

    return files;
  };

  const renderEmptyFiles = () => {
    const emptyFiles: Array<ReactElement> = [];

    while (inputsCreated < filesCount) {
      inputsCreated++;
      emptyFiles.push(
        <FieldFile
          accept={props.accept}
          changeEvent={customChange}
          extRegEx={props.extRegEx}
          key={-inputsCreated}
          label={`Файл - ${inputsCreated}`}
          name={props.name}
          value={null}
        />,
      );
    }

    return emptyFiles;
  };

  return (
    <div className='card mb-3'>
      <div className='card-header'>{props.label}</div>
      <div className='card-body'>
        {renderFiles()}
        {renderEmptyFiles()}
      </div>
      <div className='card-footer'>
        <button
          className='btn btn-info'
          type='button'
          onClick={() => setFilesCount(filesCount + 1)}
        >
          Добавить файл
        </button>
      </div>
    </div>
  );
};

export default FieldFileMultiple;
