import React, { FC, useCallback, useEffect, useState, ReactElement } from 'react';

import request from '../../lib/request';
import Faq from '../../entity/Faq';
import FaqItem from '../../components/FaqItem';

const FaqPage: FC = () => {
  const [faqList, setFaqList] = useState([] as Array<Faq>);

  const loadData = useCallback(() => {
    request('/api/faq', {
      success: (response) => {
        const faqList: Faq[] = [];

        response.data.forEach((item: Faq) => {
          const faqItem = new Faq();
          faqItem.fill(item);
          faqList.push(faqItem);
        });

        setFaqList(faqList);
      },
    });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const renderFaqList = () => {
    const faqNodes: Array<ReactElement> = [];

    faqList.forEach((item, index) => {
      faqNodes.push(
        <FaqItem
          answer={item.answers}
          imageAlt={item.file ? item.file.title : null}
          imageUrl={item.file ? item.file.path : null}
          key={index}
          question={item.question}
        />,
      );
    });

    return faqNodes;
  };

  return <>{renderFaqList()}</>;
};

export default FaqPage;
