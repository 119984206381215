import { Link } from 'react-router-dom';
import React, { FC, useState, useEffect } from 'react';

import { generateTitle, getEntityFromRoute, getRouteFromPathname } from '../../lib/functions';
import Instruction from '../../entity/Instruction';
import InstructionTree from '../../components/InstructionTree';
import EntityEditFrom from '../../components/EntityEditFrom';
import Container from '../../components/Container';

export const setEditTitle = async (): Promise<any> => {
  const entity = getEntityFromRoute();
  if (entity) {
    document.title = generateTitle(`Редактировать ${entity.labelOne}`);
  }
  return null;
};

const EntityList: FC = () => {
  const [route, setRoute] = useState(getRouteFromPathname());

  const { entity } = getRouteFromPathname();

  useEffect(() => {
    setRoute(getRouteFromPathname());
  }, [setRoute, entity]);

  const renderEditForm = () => {
    if (entity === Instruction) {
      return (
        <div className='row'>
          <div className='col-9'>
            <EntityEditFrom route={route} />
          </div>
          <div className='col-3'>
            <InstructionTree />
          </div>
        </div>
      );
    } else {
      return <EntityEditFrom route={route} />;
    }
  };

  return (
    <Container>
      <>
        <Link className='btn btn-info mb-3' to={`/${window.location.pathname.split('/')[1]}`}>
          Назад
        </Link>
        {renderEditForm()}
      </>
    </Container>
  );
};

export default EntityList;
