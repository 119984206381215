import { useNavigate } from 'react-router-dom';
import React, { FormEvent, ChangeEvent, FC, useState } from 'react';

import request from '../../lib/request';

const AuthForm: FC = () => {
  const [inProcess, setInProcess] = useState(false);
  const [fields, setFields] = useState({
    login: '',
    password: '',
  });

  const [error, setError] = useState('');

  const navigate = useNavigate();

  const { login, password } = fields;

  const inputChange = (event: ChangeEvent) => {
    setError('');
    const target = event.target as HTMLInputElement;

    if (target) {
      const { value } = target;
      const name = target.name as string;
      if (name && name in fields) {
        setFields({
          ...fields,
          [name]: value ? value : '',
        });
      }
    }
  };

  const submit = (event: FormEvent) => {
    event.preventDefault();

    setInProcess(true);
    request('/api/login', {
      method: 'POST',
      body: JSON.stringify(fields),
      success: (data) => {
        if (data.authed) {
          setError('');
          navigate('/users');
        }
      },
      done: (data) => {
        setInProcess(false);
        if (data.error) {
          setError(data.error);
        }
      },
    });
  };

  return (
    <div className='card'>
      <div className='card-header'>Авторизация</div>
      <div className='card-body'>
        <form onSubmit={submit}>
          <div className='mb-3'>
            <label className='form-label' htmlFor='login'>
              Логин
            </label>
            <input
              className='form-control'
              id='login'
              name='login'
              placeholder='name@example.com'
              type='text'
              value={login}
              onChange={inputChange}
            />
          </div>
          <div className='mb-3'>
            <label className='form-label' htmlFor='password'>
              Пароль
            </label>
            <input
              className='form-control'
              id='password'
              name='password'
              type='password'
              value={password}
              onChange={inputChange}
            />
          </div>

          {error !== '' ? <div className='alert alert-danger'>{error}</div> : null}

          <div className='mb-3 d-grid'>
            <button className='btn btn-primary' disabled={inProcess}>
              {inProcess ? '...Загрузка' : 'Войти'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthForm;
